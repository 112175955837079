<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-row>
      <v-col md="12" sm="6" cols="12">
        <v-card>
          <v-img class="white--text align-end" :src="defaultBackground()" height="200px" />
          <v-card-text class="position-relative">
            <!-- User Avatar -->
            <v-avatar size="90" color="white" class="avatar-center">
              <v-img :src="showPhoto() + `/images/member/` + databerkas.id_member + `/` + databerkas.photo"></v-img>
            </v-avatar>
            <!-- Title, Subtitle & Action Button -->
            <div class="d-flex justify-space-between flex-wrap pt-16">
              <div class="me-2 mb-2">
                <v-card-title class="pt-0 px-0">
                  {{ databerkas.nama_depan }} {{ databerkas.nama_belakang }}
                </v-card-title>
                <v-card-subtitle class="text-xs pa-0"> {{ members.countryoforigin }} </v-card-subtitle>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-text>
            <v-text-field
              class="pt-5"
              label="Search"
              @keyup="doSearch()"
              v-model="search"
              dense
              outlined
            ></v-text-field>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase">NO</th>
                    <th class="text-center text-uppercase">FILE</th>
                    <th class="text-center text-uppercase">DESCRIPTION</th>
                    <th class="text-center text-uppercase">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in displayData()" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="text-center">
                      <v-img
                        width="100px"
                        :src="
                          data.berkas.split('.')[1] == 'pdf'
                            ? showFiles()
                            : showFilesImage() + `/images/berkas/` + data.id_member + `/` + data.berkas
                        "
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </td>
                    <td class="text-center">
                      {{ data.keterangan }}
                    </td>

                    <td class="text-center">
                      <span v-if="data.berkas.split('.')[1] == 'pdf'">
                        <a :href="downloadBerkasPDF() + `/images/berkas/` + data.id_member + `/` + data.berkas">
                          <v-btn color="success" class="mt-4" type="button">
                            <v-icon color="#ECEFF1">
                              {{ icons.mdiCloudDownload }}
                            </v-icon>
                          </v-btn>
                        </a>
                      </span>
                      <span v-else>
                        <v-dialog transition="dialog-bottom-transition" max-width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="downloadBerkas(data.berkas, data.id_member)"
                              color="success"
                              class="mt-4"
                              type="button"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#ECEFF1">
                                {{ icons.mdiCloudDownload }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark>BERKAS</v-toolbar>
                              <v-card-text>
                                <div class="text-h2 pa-18">
                                  <center>
                                    <img
                                      style="padding-top: 10px"
                                      width="450px"
                                      :src="downloadBerkas() + `/images/berkas/` + data.id_member + `/` + data.berkas"
                                    />
                                    <div
                                      style="padding-top: 10px; font-size: 23px"
                                      class="text-uppercase font-weight-medium"
                                    >
                                      {{ data.keterangan }}
                                    </div>
                                  </center>
                                </div>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn text @click="dialog.value = false">Close</v-btn>
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </span>

                      &nbsp;
                      <v-btn
                        :to="{
                          name: 'berkas-edit',
                          params: { id_berkas: data.id_berkas },
                        }"
                        color="warning"
                        class="me-3 mt-4"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiGreasePencil }}
                        </v-icon>
                      </v-btn>

                      <v-btn @click="deletePost(data.id_berkas, index)" color="error" class="mt-4" type="button">
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <center>
                        <div class="v-data-footer">
                          <span class="v-data-footer__icons-before" bis_skin_checked="1">
                            <button
                              type="button"
                              @click="page--"
                              :class="[
                                'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page == 1 ? 'v-btn--disabled' : '',
                              ]"
                              aria-label="Previous page"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                          <span
                            :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                            v-for="pageNumber in pages.slice(page - 1, page + 5)"
                            :key="pageNumber"
                            @click="page = pageNumber"
                          >
                            <button
                              type="button"
                              class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                              aria-label="Next page"
                            >
                              {{ pageNumber }}
                            </button>
                          </span>
                          <span class="v-data-footer__icons-after" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page < pages.length ? '' : 'v-btn--disabled',
                              ]"
                              aria-label="Next page"
                              @click="page++"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                        </div>
                      </center>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiCheckBold,
  mdiCloudDownload,
  mdiSlashForward,
} from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Files',
          disabled: false,
          href: '/admin/berkas',
        },
        {
          text: 'Show Files',
          disabled: true,
        },
      ],

      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiDelete,
        mdiGreasePencil,
        mdiContentSave,
        mdiCheckBold,
        mdiCloudDownload,
        mdiSlashForward,
      },

      ext: [],
      FileBerkasMember: [],

      databerkas: [],
      errors: [],

      berkasmembers: [],
      members: [],

      page: 1,
      perPage: 5,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getAllMembers()
      this.getAllBerkas()
      this.defaultBackground()
      this.showFiles()
      this.showFilesImage()
      this.showPhoto()

      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    downloadBerkasPDF() {
      let FileBerkasMemberPdf = process.env.VUE_APP_ROOT_API
      return FileBerkasMemberPdf
    },
    downloadBerkas(berkas, id_member) {
      let BerkasImages = process.env.VUE_APP_ROOT_API
      return BerkasImages
    },
    showPhoto() {
      let ShowPhotosMember = process.env.VUE_APP_ROOT_API
      return ShowPhotosMember
    },
    showFilesImage() {
      let FileBerkasMemberPdf = process.env.VUE_APP_ROOT_API
      return FileBerkasMemberPdf
    },
    showFiles() {
      let FileBerkasMember = process.env.VUE_APP_ROOT_API + `/images/berkas/pdf.jpg`
      return FileBerkasMember
    },
    defaultBackground() {
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/berkas/background-berkas.png`
      return imageDefault
    },
    getAllMembers() {
      localStorage.getItem('token')
      let uriClients = process.env.VUE_APP_ROOT_API + `/api/berkas/${this.$route.params.id_berkas}/show`
      this.axios.get(uriClients).then(response => {
        this.databerkas = response.data.berkas
        this.members = response.data.members
      })
    },
    getAllBerkas() {
      //state token
      localStorage.getItem('token')

      let urlClients = process.env.VUE_APP_ROOT_API + `/api/berkas/${this.$route.params.id_berkas}/memberberkasshow`
      return this.axios.get(urlClients).then(response => {
        this.berkasmembers = response.data.berkasmember
      })
    },

    defautlPhoto() {
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/berkas/no-images.png`
      return imageDefault
    },
    deletePhotoBerkas(index) {
      this.databerkas.berkas.splice(index, 1)
    },

    deletePost(id, index) {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriDelete = process.env.VUE_APP_ROOT_API + `/api/berkas/${id}`
            this.axios
              .delete(uriDelete)
              .then(response => {
                this.berkas.splice(this.berkas.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Berkas deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })

                  // window.location.reload(true)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann delete berkas!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                }
              })
              .catch(e => {})

            setTimeout(() => {
              this.$router.push({ name: 'berkas-index' })
              // window.location.reload(true)
            }, 1000)
          }
        })
    },
    doSearch() {
      let go = this.berkasmembers.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.keterangan.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.berkasmembers)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.berkasmembers.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(berkasmembers) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return berkasmembers.slice(from, to)
    },
  },
  watch: {
    berkasmembers() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 6px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
